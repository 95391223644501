
var Fyno = {

     InitFyno: function(){
        window.$sidebar = $('.sidebar');

        window.$full_page = $('.full-page');

        window.$sidebar_responsive = $('body > .navbar-collapse');

        window.window_width = $(window).width();

        var id_user = $("#id_user").val();
        Fyno.InitDashboardCharts(id_user);
        //Fyno.initCharts();
     },
    presentationAnimations: function () {
        $(function () {

            var $window = $(window),
                isTouch = Modernizr.touch;

            if (isTouch) {
                $('.add-animation').addClass('animated');
            }

            $window.on('scroll', revealAnimation);

            function revealAnimation() {
                // Showed...
                $(".add-animation:not(.animated)").each(function () {
                    var $this = $(this),
                        offsetTop = $this.offset().top,
                        scrolled = $window.scrollTop(),
                        win_height_padded = $window.height();
                    if (scrolled + win_height_padded > offsetTop) {
                        $this.addClass('animated');
                    }
                });
                // Hidden...
                $(".add-animation.animated").each(function (index) {
                    var $this = $(this),
                        offsetTop = $this.offset().top;
                    scrolled = $window.scrollTop(),
                        windowHeight = $window.height();
                    win_height_padded = windowHeight * 0.8;
                    if (scrolled + win_height_padded < offsetTop) {
                        $(this).removeClass('animated');
                    }
                });
            }

            revealAnimation();
        });

    },


    checkFullPageBackgroundImage: function () {
       var $page = $('.full-page');
       var image_src = $page.data('image');

        if (image_src !== undefined) {
            image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>';
            $page.append(image_container);
        }
    },

    InitMaterialWizard: function () {
        // Code for the Validator
        var $validator = $('.card-wizard form').validate({
            rules: {
                firstname: {
                    required: true,
                    minlength: 3
                },
                lastname: {
                    required: true,
                    minlength: 3
                },
                email: {
                    required: true,
                    minlength: 3,
                }
            },

            highlight: function (element) {
                $(element).closest('.form-group').removeClass('has-success').addClass('has-danger');
            },
            success: function (element) {
                $(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
            },
            errorPlacement: function (error, element) {
                $(element).append(error);
            }
        });

        // Wizard Initialization
        $('.card-wizard').bootstrapWizard({
            'tabClass': 'nav nav-pills',
            'nextSelector': '.btn-next',
            'previousSelector': '.btn-previous',

            onNext: function (tab, navigation, index) {
                var $valid = $('.card-wizard form').valid();
                if (!$valid) {
                    $validator.focusInvalid();
                    return false;
                }
            },

            onInit: function (tab, navigation, index) {
                //check number of tabs and fill the entire row
                var $total = navigation.find('li').length;
                var $wizard = navigation.closest('.card-wizard');

                $first_li = navigation.find('li:first-child a').html();
                $moving_div = $('<div class="moving-tab">' + $first_li + '</div>');
                $('.card-wizard .wizard-navigation').append($moving_div);

                refreshAnimation($wizard, index);

                $('.moving-tab').css('transition', 'transform 0s');
            },

            onTabClick: function (tab, navigation, index) {
                var $valid = $('.card-wizard form').valid();

                if (!$valid) {
                    return false;
                } else {
                    return true;
                }
            },

            onTabShow: function (tab, navigation, index) {
                var $total = navigation.find('li').length;
                var $current = index + 1;

                var $wizard = navigation.closest('.card-wizard');

                // If it's the last tab then hide the last button and show the finish instead
                if ($current >= $total) {
                    $($wizard).find('.btn-next').hide();
                    $($wizard).find('.btn-finish').show();
                } else {
                    $($wizard).find('.btn-next').show();
                    $($wizard).find('.btn-finish').hide();
                }

                button_text = navigation.find('li:nth-child(' + $current + ') a').html();

                setTimeout(function () {
                    $('.moving-tab').text(button_text);
                }, 150);

                var checkbox = $('.footer-checkbox');

                if (!index == 0) {
                    $(checkbox).css({
                        'opacity': '0',
                        'visibility': 'hidden',
                        'position': 'absolute'
                    });
                } else {
                    $(checkbox).css({
                        'opacity': '1',
                        'visibility': 'visible'
                    });
                }

                refreshAnimation($wizard, index);
            }
        });


        // Prepare the preview for profile picture
        $("#wizard-picture").change(function () {
            readURL(this);
        });

        $('[data-toggle="wizard-radio"]').click(function () {
            wizard = $(this).closest('.card-wizard');
            wizard.find('[data-toggle="wizard-radio"]').removeClass('active');
            $(this).addClass('active');
            $(wizard).find('[type="radio"]').removeAttr('checked');
            $(this).find('[type="radio"]').attr('checked', 'true');
        });

        $('[data-toggle="wizard-checkbox"]').click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).find('[type="checkbox"]').removeAttr('checked');
            } else {
                $(this).addClass('active');
                $(this).find('[type="checkbox"]').attr('checked', 'true');
            }
        });

        $('.set-full-height').css('height', 'auto');

        //Function to show image before upload

        function readURL(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    $('#wizardPicturePreview').attr('src', e.target.result).fadeIn('slow');
                };
                reader.readAsDataURL(input.files[0]);
            }
        }

        $(window).resize(function () {
            $('.card-wizard').each(function () {
                $wizard = $(this);

                index = $wizard.bootstrapWizard('currentIndex');
                refreshAnimation($wizard, index);

                $('.moving-tab').css({
                    'transition': 'transform 0s'
                });
            });
        });

        function refreshAnimation($wizard, index) {
            $total = $wizard.find('.nav li').length;
            $li_width = 100 / $total;

            total_steps = $wizard.find('.nav li').length;
            move_distance = $wizard.width() / total_steps;
            index_temp = index;
            vertical_level = 0;

            mobile_device = $(document).width() < 600 && $total > 3;

            if (mobile_device) {
                move_distance = $wizard.width() / 2;
                index_temp = index % 2;
                $li_width = 50;
            }

            $wizard.find('.nav li').css('width', $li_width + '%');

            step_width = move_distance;
            move_distance = move_distance * index_temp;

            $current = index + 1;

            if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
                move_distance -= 8;
            } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
                move_distance += 8;
            }

            if (mobile_device) {
                vertical_level = parseInt(index / 2);
                vertical_level = vertical_level * 38;
            }

            $wizard.find('.moving-tab').css('width', step_width);
            $('.moving-tab').css({
                'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
                'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

            });
        }
    },


    abbrNum: function (number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10, decPlaces);

        // Enumerate number abbreviations
        var abbrev = ["k", "m", "b", "t"];

        // Go through the array backwards, so we do the largest first
        for (var i = abbrev.length - 1; i >= 0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10, (i + 1) * 3);

            // If the number is bigger or equal do the abbreviation
            if (size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number * decPlaces / size) / decPlaces;

                // Handle special case where we round up to the next abbreviation
                if ((number == 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }

        return number;
    },
    InitDashboardCharts: function (id_user) {

        /*-
        if ( $('#dealySpeedChart').length != 0) {
            var AvgDealySpeed = [];
                    axios
                    .post("api/dashboard/avgdealyspeed",{
                        id_user: id_user
                    })
                    .then(function(response) {
                    console.log(response);
                    var data = response.data;
                    var arrayMax = Function.prototype.apply.bind(Math.max, null);

                    datadealySpeedChart = {
                        labels: data[1],
                        series: [data[0]]
                    };

                    optionsdealySpeedChart = {
                        lineSmooth: Chartist.Interpolation.cardinal({
                            tension: 0
                        }),
                        low: 0,
                        high: arrayMax(data[0]) + 15, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                        chartPadding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        }
                    };

                    var dealySpeedChart = new Chartist.Line('#dealySpeedChart', datadealySpeedChart, optionsdealySpeedChart);

                    // start animation for the Completed Tasks Chart - Line Chart
                    md.startAnimationForLineChart(dealySpeedChart);
                    })
                    .catch(function(error) {
                    console.log(error);
                    });
                }
        */

        if ($('#dailyFuelChart').length != 0) {
            /* ----------==========     Daily Fuel Chart initialization    ==========---------- */

            var datadailyFuelChart = {
                labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                series: [
                    //[12, 17, 7, 17, 23, 18, 38]
                    [0, 0, 0, 0, 0, 0, 0]
                ]
            };

            var optionsdailyFuelChart = {
                lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0
                }),
                low: 0,
                high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                chartPadding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
            };

            var dailyFuelChart = new Chartist.Line('#dailyFuelChart', datadailyFuelChart, optionsdailyFuelChart);

            md.startAnimationForLineChart(dailyFuelChart);
        }
        if ($('#dealyDistanceChart').length != 0) {

            /* ----------==========     data dealy Speed Chart initialization    ==========---------- */



            axios.post("api/dashboard/weeklydistance",{
                id_user: id_user
              })
            .then(function(response) {
            //   console.log(response);
              var data = response.data;
              //console.log(data[1]);
            //   console.log(data[0]);
              var distdata = [];
              for (var i = 0; i < data[0].length; i++) {

                  distdata.push(Fyno.abbrNum(data[0][i], 2));
              }

              var didata = [];
              for (i = 0; i < data[0].length; i++) {

                  didata.push(Helpers.precisionRound(data[0][i] / 100, 0));
              }
              //console.log(didata);
              var arrayMax = Function.prototype.apply.bind(Math.max, null);

              var datadealyDistanceChart = {
                  labels: data[1],
                  series: [
                      didata

                  ]
              };
              var optionsdealyDistanceChart = {
                  axisX: {
                      showGrid: false
                  },
                  low: 0,
                  high: arrayMax(didata) + 100,
                  chartPadding: {
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 34
                  }
              };
              var responsiveOptions = [
                  ['screen and (max-width: 640px)', {
                      seriesBarDistance: 5,
                      axisX: {
                          labelInterpolationFnc: function (value) {
                              return value[0];
                          }
                      }
                  }]
              ];
              var dealyDistanceChart = Chartist.Bar('#dealyDistanceChart', datadealyDistanceChart, optionsdealyDistanceChart, responsiveOptions);

              //start animation for the Emails Subscription Chart
              md.startAnimationForBarChart(dealyDistanceChart);
              $('#fyno_spinner').hide();
            })
            .catch(function(error) {
              console.log(error);
              $('#fyno_spinner').hide();
            });

        }
    },

    // initCharts: function () {
    //     if ($('#roundedLineChart').length != 0 && $('#straightLinesChart').length != 0 && $('#colouredRoundedLineChart').length != 0 && $('#colouredBarsChart').length != 0 && $('#simpleBarChart').length != 0 && $('#multipleBarsChart').length != 0) {
    //         /* ----------==========    Rounded Line Chart initialization    ==========---------- */

    //         dataRoundedLineChart = {
    //             labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    //             series: [
    //                 [12, 17, 7, 17, 23, 18, 38]
    //             ]
    //         };

    //         optionsRoundedLineChart = {
    //             lineSmooth: Chartist.Interpolation.cardinal({
    //                 tension: 10
    //             }),
    //             axisX: {
    //                 showGrid: false,
    //             },
    //             low: 0,
    //             high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    //             chartPadding: {
    //                 top: 0,
    //                 right: 0,
    //                 bottom: 0,
    //                 left: 0
    //             },
    //             showPoint: false
    //         };

    //         var RoundedLineChart = new Chartist.Line('#roundedLineChart', dataRoundedLineChart, optionsRoundedLineChart);

    //         md.startAnimationForLineChart(RoundedLineChart);


    //         /*  **************** Straight Lines Chart - single line with points ******************** */

    //         dataStraightLinesChart = {
    //             labels: ['\'07', '\'08', '\'09', '\'10', '\'11', '\'12', '\'13', '\'14', '\'15'],
    //             series: [
    //                 [10, 16, 8, 13, 20, 15, 20, 34, 30]
    //             ]
    //         };

    //         optionsStraightLinesChart = {
    //             lineSmooth: Chartist.Interpolation.cardinal({
    //                 tension: 0
    //             }),
    //             low: 0,
    //             high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    //             chartPadding: {
    //                 top: 0,
    //                 right: 0,
    //                 bottom: 0,
    //                 left: 0
    //             },
    //             classNames: {
    //                 point: 'ct-point ct-white',
    //                 line: 'ct-line ct-white'
    //             }
    //         };

    //         var straightLinesChart = new Chartist.Line('#straightLinesChart', dataStraightLinesChart, optionsStraightLinesChart);

    //         md.startAnimationForLineChart(straightLinesChart);


    //         /*  **************** Coloured Rounded Line Chart - Line Chart ******************** */


    //         dataColouredRoundedLineChart = {
    //             labels: ['\'06', '\'07', '\'08', '\'09', '\'10', '\'11', '\'12', '\'13', '\'14', '\'15'],
    //             series: [
    //                 [287, 480, 290, 554, 690, 690, 500, 752, 650, 900, 944]
    //             ]
    //         };

    //         optionsColouredRoundedLineChart = {
    //             lineSmooth: Chartist.Interpolation.cardinal({
    //                 tension: 10
    //             }),
    //             axisY: {
    //                 showGrid: true,
    //                 offset: 40
    //             },
    //             axisX: {
    //                 showGrid: false,
    //             },
    //             low: 0,
    //             high: 1000,
    //             showPoint: true,
    //             height: '300px'
    //         };


    //         var colouredRoundedLineChart = new Chartist.Line('#colouredRoundedLineChart', dataColouredRoundedLineChart, optionsColouredRoundedLineChart);

    //         md.startAnimationForLineChart(colouredRoundedLineChart);


    //         /*  **************** Coloured Rounded Line Chart - Line Chart ******************** */


    //         dataColouredBarsChart = {
    //             labels: ['\'06', '\'07', '\'08', '\'09', '\'10', '\'11', '\'12', '\'13', '\'14', '\'15'],
    //             series: [
    //                 [287, 385, 490, 554, 586, 698, 695, 752, 788, 846, 944],
    //                 [67, 152, 143, 287, 335, 435, 437, 539, 542, 544, 647],
    //                 [23, 113, 67, 190, 239, 307, 308, 439, 410, 410, 509]
    //             ]
    //         };

    //         optionsColouredBarsChart = {
    //             lineSmooth: Chartist.Interpolation.cardinal({
    //                 tension: 10
    //             }),
    //             axisY: {
    //                 showGrid: true,
    //                 offset: 40
    //             },
    //             axisX: {
    //                 showGrid: false,
    //             },
    //             low: 0,
    //             high: 1000,
    //             showPoint: true,
    //             height: '300px'
    //         };


    //         var colouredBarsChart = new Chartist.Line('#colouredBarsChart', dataColouredBarsChart, optionsColouredBarsChart);

    //         md.startAnimationForLineChart(colouredBarsChart);



    //         /*  **************** Public Preferences - Pie Chart ******************** */

    //         var dataPreferences = {
    //             labels: ['62%', '32%', '6%'],
    //             series: [62, 32, 6]
    //         };

    //         var optionsPreferences = {
    //             height: '230px'
    //         };

    //         Chartist.Pie('#chartPreferences', dataPreferences, optionsPreferences);

    //         /*  **************** Simple Bar Chart - barchart ******************** */

    //         var dataSimpleBarChart = {
    //             labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //             series: [
    //                 [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]
    //             ]
    //         };

    //         var optionsSimpleBarChart = {
    //             seriesBarDistance: 10,
    //             axisX: {
    //                 showGrid: false
    //             }
    //         };

    //         var responsiveOptionsSimpleBarChart = [
    //             ['screen and (max-width: 640px)', {
    //                 seriesBarDistance: 5,
    //                 axisX: {
    //                     labelInterpolationFnc: function (value) {
    //                         return value[0];
    //                     }
    //                 }
    //             }]
    //         ];

    //         var simpleBarChart = Chartist.Bar('#simpleBarChart', dataSimpleBarChart, optionsSimpleBarChart, responsiveOptionsSimpleBarChart);

    //         //start animation for the Emails Subscription Chart
    //         md.startAnimationForBarChart(simpleBarChart);


    //         var dataMultipleBarsChart = {
    //             labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //             series: [
    //                 [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
    //                 [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
    //             ]
    //         };

    //         var optionsMultipleBarsChart = {
    //             seriesBarDistance: 10,
    //             axisX: {
    //                 showGrid: false
    //             },
    //             height: '300px'
    //         };

    //         var responsiveOptionsMultipleBarsChart = [
    //             ['screen and (max-width: 640px)', {
    //                 seriesBarDistance: 5,
    //                 axisX: {
    //                     labelInterpolationFnc: function (value) {
    //                         return value[0];
    //                     }
    //                 }
    //             }]
    //         ];


    //         var multipleBarsChart = Chartist.Bar('#multipleBarsChart', dataMultipleBarsChart, optionsMultipleBarsChart, responsiveOptionsMultipleBarsChart);

    //         //start animation for the Emails Subscription Chart
    //         md.startAnimationForBarChart(multipleBarsChart);
    //     }

    // },
    showSwal: function (type) {
        if (type == 'basic') {
           Swal.fire({
                title: "Here's a message!",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-success"
            }).catch(swal.noop);

        } else if (type == 'title-and-text') {
           Swal.fire({
                title: "Here's a message!",
                text: "It's pretty, isn't it?",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-info"
            }).catch(swal.noop);

        } else if (type == 'success-message') {
           Swal.fire({
                title: "Good job!",
                text: "You clicked the button!",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-success",
                type: "success"
            }).catch(swal.noop);

        } else if (type == 'warning-message-and-confirmation') {
           Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: false
            }).then(function () {
               Swal.fire({
                    title: 'Deleted!',
                    text: 'Your file has been deleted.',
                    type: 'success',
                    confirmButtonClass: "btn btn-success",
                    buttonsStyling: false
                });
            }).catch(swal.noop);
        } else if (type == 'warning-message-and-cancel') {
           Swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this imaginary file!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
                buttonsStyling: false
            }).then(function () {
               Swal.fire({
                    title: 'Deleted!',
                    text: 'Your imaginary file has been deleted.',
                    type: 'success',
                    confirmButtonClass: "btn btn-success",
                    buttonsStyling: false
                }).catch(swal.noop);
            }, function (dismiss) {
                // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
                if (dismiss === 'cancel') {
                   Swal.fire({
                        title: 'Cancelled',
                        text: 'Your imaginary file is safe :)',
                        type: 'error',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false
                    }).catch(swal.noop);
                }
            });

        } else if (type == 'custom-html') {
           Swal.fire({
                title: 'HTML example',
                buttonsStyling: false,
                confirmButtonClass: "btn btn-success",
                html: 'You can use <b>bold text</b>, ' +
                    '<a href="http://github.com">links</a> ' +
                    'and other HTML tags'
            }).catch(swal.noop);

        } else if (type == 'auto-close') {
           Swal.fire({
                title: "Auto close alert!",
                text: "I will close in 2 seconds.",
                timer: 2000,
                showConfirmButton: false
            }).catch(swal.noop);
        } else if (type == 'input-field') {
           Swal.fire({
                title: 'Input something',
                html: '<div class="form-group">' +
                    '<input id="input-field" type="text" class="form-control" />' +
                    '</div>',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false
            }).then(function (result) {
               Swal.fire({
                    type: 'success',
                    html: 'You entered: <strong>' +
                        $('#input-field').val() +
                        '</strong>',
                    confirmButtonClass: 'btn btn-success',
                    buttonsStyling: false

                });
            }).catch(swal.noop);
        }
    },
    showNotification: function (from, align) {
        type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];

        color = Math.floor((Math.random() * 6) + 1);

        $.notify({
            icon: "notifications",
            message: "Welcome to <b>Material Dashboard</b> - a beautiful freebie for every web developer."

        }, {
            type: type[color],
            timer: 3000,
            placement: {
                from: from,
                align: align
            }
        });
    }

 
};

Fyno.InitFyno(); 
export default Fyno;
window.Fyno = Fyno;

